import React, { Component } from 'react'
import cn from 'clsx'
import FullscreenImage from '@components/FullscreenImage'
import VideoBgImage from '@images/video-bg.jpg'
import Layout from '@layout'

class VideoPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imageLoaded: false,
      videoLoaded: false,
    }
    this.iframeLoaded = this.iframeLoaded.bind(this)
  }

  iframeLoaded() {
    this.setState({ videoLoaded: true })
  }

  render() {
    const pageClass = cn('videopage', {
      imageLoaded: this.state.imageLoaded,
      videoLoaded: this.state.videoLoaded,
    })
    return (
      <Layout>
      <div className={pageClass}>
        <FullscreenImage
          image={VideoBgImage}
          loaded={() => this.setState({ imageLoaded: true })}
        />
        <div className="container">
          <div className="video-container">
            <div className="embed-container">
              <iframe
                src="https://www.youtube.com/embed/87K1Ctaxdk4"
                onLoad={this.iframeLoaded}
                frameBorder="0"
                allowFullScreen
              />
            </div>
          </div>
          <div className="video-container">
            <div className="embed-container">
              <iframe
                src="https://www.youtube.com/embed/NPxyZgjwAfQ"
                onLoad={this.iframeLoaded}
                frameBorder="0"
                allowFullScreen
              />
            </div>
          </div>
          <div className="video-container">
            <div className="embed-container">
              <iframe
                src="https://www.youtube.com/embed/8eQdBYxSz7o"
                onLoad={this.iframeLoaded}
                frameBorder="0"
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </div>
      </Layout>
    )
  }
}

export default VideoPage
